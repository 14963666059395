import React, { useContext } from 'react';
import Slider from 'react-slick';
import { LanguageContext } from 'context';

const ImagesGrid = ({ component }) => {
  const { images } = component;
  const currentLang = useContext(LanguageContext).currentLang;
  const rtl_slick = () => {
    return currentLang === 'ar-SA' ? true : false;
  };

  const responsiveSettings = [
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ];

  const defaultSlidesToShow = 3; // Adjust this based on your default settings

  const sliderSettings = {
    infinite: true,
    speed: 400,
    slidesToShow: defaultSlidesToShow,
    slidesToScroll: 1,
    dots: false,
    swipe: true,
    cssEase: 'linear',
    fade: false,
    draggable: true,
    centerMode: false,
    arrows: false,
    rtl: rtl_slick(),
    initialSlide: `${currentLang}` === 'ar-SA' ? images.length - defaultSlidesToShow : 0,
    responsive: responsiveSettings,
  };
  return (
    <>
      {/* {images.map((image, index) => {
        return (
          <div key={index}>
            <img alt={image.description || image.title} src={image.file.url} />
          </div>
        );
      })} */}
      <Slider {...sliderSettings}>
        {images.map((image) => {
          return <img alt={image.description || image.title} src={image.file.url} />;
        })}
      </Slider>
    </>
  );
};

export default ImagesGrid;
