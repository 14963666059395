import React from 'react';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import { Controller, Scene } from 'react-scrollmagic';

const OurVision = ({ component }) => {
  const { shortTitle, mainTitle, personName, personPosition, description, personImage } = component;
  const imagePath = getImage(personImage);

  return (
    <section className='our-vision-area' id='vision-wrapper'>
      <Controller>
        <div className='our-vision-wrapper' dir='ltr'>
          <div className='our-vision-img'>
            <GatsbyImage image={imagePath} alt={personImage.description} />
          </div>

          <div className='our-vision-content'>
            <Scene offset={-200} classToggle='animate__fadeInUp' triggerElement='#vision-wrapper' reverse={true}>
              <div className='animate__animated'>
                <div className='short-title'>{shortTitle}</div>
                <h1>{mainTitle}</h1>
                {description && <p className='description text-justify'>{description?.description}</p>}
                <h2 className='person-details'>
                  {personName}, {personPosition}
                </h2>
              </div>
            </Scene>
          </div>
        </div>
      </Controller>
    </section>
  );
};

export default OurVision;
